<template>
  <div id="resource">
    <div class="wrapper border-bottom-black">
      <div class="bottom">
        <div class="left">
          <div class="item1">
            <span class="name">云端存储空间</span>
            <span class="stockpile">已使用 {{ (this.storageSpace.use_space/1024).toFixed(2) }} GB/{{
                (this.storageSpace.storage_space/1024).toFixed(2) || 0
              }}
              GB</span>
          </div>
          <div class="item2">
            <Progress :percent="storageSpaceRatio"
                      :stroke-color="strokeColor"
                      style="width: 400px" />
          </div>
        </div>
        <!-- <div class="right">
          <Button type="primary"
                  to="/resourceIndex/Terra"
                  style="margin-right: 15px"
                  v-show="TerraShow">云计算</Button>

          <Button type="primary"
                  @click="addModalshow">上传模型</Button>&nbsp; -->
        <!-- </div> -->
      </div>
    </div>
    <div class="conent"
         v-if="modelData.length > 0">
      <div v-for="item in modelData"
           :key="item.id"
           class="map-box">
        <span class="type-name"
              @click="lookOrthophotoFn(item)">正射</span>

        <div :id="'map' + item.id"
             class="map"
             @click="lookOrthophotoFn(item)"></div>
        <div class="info">
          <p class="title"
             @click="lookOrthophotoFn(item)"
             :title="item.mapname">{{ item.mapname }}</p>
          <span>{{ item.create_time }}</span>
          <!-- 操作 -->
          <div class="more"
               @click="showMore(item.id)">
            <Icon type="md-more"
                  size="22" />
            <div :style="{display: moreAct == item.id ?'block':'none'}">
              <p @click.stop="edit(item)">
                <Icon type="md-create"
                      style="margin-right: 0.15rem" />重命名
              </p>
              <p @click.stop="del(item)">
                <Icon type="ios-trash"
                      style="margin-right: 0.15rem" />删除
              </p>
            </div>
          </div>

        </div>
      </div>
      <!-- <Table :loading="loading"
             :columns="columns"
             :data="modelData"></Table> -->
      <div style="margin-top: 15px;clear:both">
        <Page :total="total"
              show-total
              v-if="total > 10"
              :page-size="limit"
              style="text-align: center"
              :current="parseInt(page)"
              size="small"
              show-sizer
              :page-size-opts='[10,15,20]'
              @on-page-size-change="pageSizeChange"
              @on-change="changePage" />
      </div>
    </div>
    <div v-else
         class="noMedia">
      <img :src="noData"
           alt="">
      暂无正射文件
    </div>

    <!--弹框区-->
    <!--删除模型-->
    <Modal v-model="deleteModelShou"
           width="360">
      <p slot="header"
         style="color: #f60; text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>删除此模型</span>
      </p>
      <div style="text-align: center">
        <p>
          是否确认删除
          <span style="color: red">{{
            this.deleteModelData.mapname || ""
          }}</span>
          ?<br />一经删除无法恢复.
        </p>
      </div>
      <div slot="footer">
        <Button type="error"
                size="large"
                long
                @click="remove">确认删除</Button>
      </div>
    </Modal>
    <!--添加正射-->
    <!-- <Modal v-model="addModelShou"
           footer-hide
           :transfer='false'
           z-indx=1000
           width=680
           @on-cancel="closeAddModel"
           :mask-closable="false">
      <p slot="header"
         style="text-align: center">
        上传正射
      </p>
      
      <div>
        <p style="font-size:12px">名称:</p>
        <Input v-model="orthographicUpLoadData.mapname"
               placeholder="必填"
               maxlength="15"
               show-word-limit></Input>

        <p style="font-size:12px;margin:25px 0 5px">位置区域： {{ location }}</p>
        <div id='map'>
          <div class="mapSearch"
               v-if="$online">
            <Icon type="ios-search"
                  style="margin:0 12px"
                  size="18"
                  color="#777" />
            <input type="text"
                   id="mapSearch_3"
                   autocomplete="off"
                   placeholder="搜索地点" />
          </div>

          <div class='tiptop'
               v-if="!orthographicUpLoadData.lat">单击地图选择拍摄地点</div>
          <div id="addMap"></div>
        </div>
        <Upload ref="OrUpLoad"
                name="fileUpload"
                :before-upload="onBefore"
                :show-upload-list="true"
                :format="['zip', 'ZIP']"
                :max-size='31457280'
                :on-exceeded-size="handleMaxSize"
                :on-format-error="handleFormatError"
                :on-success="OrthophotoSuccess"
                :on-error="OrthophotoError"
                :disabled="uploadDisabled"
                :on-progress="onProgress"
                :data="upLoadFileData"
                :action="CommonUploadURL">
          <Button type="primary"
                  style="display: inline-block; width: 648px;margin:20px auto 0">上传</Button>
        </Upload>

      </div>
    </Modal> -->
    <div class='finish'
         v-if="beUploading">
      <p>{{finish?'正在解析文件,请等待':'正在上传文件,请勿关闭窗口!'}}</p>
      <Progress :percent="percent"
                :stroke-width="25"
                style="width:500px"
                status="active"
                text-inside />
    </div>

    <Modal v-model="redactModelShou"
           @on-cancel="closeredactModel"
           :mask-closable="false">
      <p slot="header"
         style="text-align: center">
        <span>模型重命名</span>
      </p>
      <div style="padding: 10px 30px">
        <Form ref="redactModel"
              :model="redactModel"
              label-position="top">
          <FormItem label="修改模型名称"
                    prop="name">
            <Input v-model="redactModel.name"></Input>
          </FormItem>
        </Form>
      </div>
      <div style="text-align: center"
           slot="footer">
        <Button type="info"
                @click="redactModel_ok"
                style="width: 200px; letter-spacing: 8px">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
// import model_icon from '@/assets/img/Icon/model_icon.png';
// import orthographic from '@/assets/img/Icon/orthographic.png';
import noData from '@/assets/img/noData.png';
import Api from '@/utils/api.js';
import {
  UploadFile,
  AddYinshe,
  AddModelIcon,
  ModelIconList,
  DelModelIcon,
} from '@/utils/javaApi.js';
export default {
  name: 'index',

  data() {
    return {
      noData,
      limit: 10,
      teamID: -1, // 团队id
      userType: '',
      userInfo: {},
      Project1info: {},
      storageSpace: {},
      storageSpaceRatio: 0,
      strokeColor: ['#108ee9', '#87d068'],

      addMap: {}, // 新增窗口的地图
      columns: [
        {
          title: '#',
          key: 'id',
          width: 80,
          align: 'center',
        },
        {
          title: '模型名称',
          key: 'mapname',
          align: 'center',
        },
        {
          title: '文件大小/MB',
          key: 'file_size',
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 450,
          render: (h, params) => {
            return h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'info',
                    size: 'small',
                  },
                  style: {
                    marginRight: '10px',
                  },
                  on: {
                    click: () => {
                      this.lookOrthophotoFn(params.row);
                    },
                  },
                },
                '查看'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  style: {
                    marginRight: '10px',
                  },
                  on: {
                    click: () => {
                      this.redactModelData = params.row;
                      this.redactModel.name = params.row.mapname;
                      this.redactModel.tid = params.row.team_id;

                      this.redactModelShou = true;
                    },
                  },
                },
                '重命名'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'error',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      if (this.userInfo.is_manager != 1) {
                        this.$Message.error('飞手账号无删除权限');
                        return;
                      } else {
                        this.deleteModelShou = true;
                        this.deleteModelData = params.row;
                      }
                    },
                  },
                },
                '删除'
              ),
            ]);
          },
        },
      ],
      modelData: [],
      loading: false,
      deleteModelShou: false,
      deleteModelData: {},
      addModelShou: false,
      addModel: {},
      //上传地址
      CommonUploadURL: '',
      // 上传的数据
      //正射
      orthographicUpLoadData: {
        mapname: '',
        team_id: '',
        lng: '', //经纬度
        lat: '',
      },
      location: '',
      // 编辑
      redactModelShou: false,
      redactModelData: {},
      redactModel: {
        name: '',
        tid: '',
      },
      map: null,
      TerraShow: false,
      aaa: [], //可以看到云计算的账号
      showPage: false,
      total: 0,
      page: 1,
      upLoadFileData: {
        dir: '正射拼图',
        token: '',
      },
      file_size: 0,
      uploadDisabled: false,
      finish: false,
      beUploading: false,
      percent: 0,
      moreAct: '-1',
    };
  },

  methods: {
    showMore(e) {
      if (this.moreAct == e) {
        this.moreAct = '-1';
      } else {
        this.moreAct = e;
      }
    },
    addModalshow() {
      this.addModelShou = !this.addModelShou;
      this.chooseType();
      this.location = '';
    },
    getAddsByPosition(mylnglat) {
      let that = this;
      this.addMap.plugin('AMap.Geocoder', function () {
        let geocoder = new AMap.Geocoder();
        geocoder.getAddress(mylnglat, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            that.location = result.regeocode.formattedAddress;
          }
        });
      });
    },

    onBefore(file) {
      // 开始上传前,选中文件后执行
      if (!this.orthographicUpLoadData.mapname) {
        this.$Message.warning('请先填写正射名称');
        this.file_size = 0;
        return false;
      } else if (
        !this.orthographicUpLoadData.lat ||
        !this.orthographicUpLoadData.lng
      ) {
        this.$Message.warning('请先选择位置信息');
        this.file_size = 0;
        return false;
      }
      if (
        this.storageSpace.storage_space * 1048576 -
          this.storageSpace.use_space * 1048576 <
        file.size
      ) {
        this.$Message.warning('团队云端存储空间不足');
        this.file_size = 0;
        return false;
      }
      this.file_size = file.size;
    },
    // 开始文件上传,禁用上传组件
    onProgress(event, file) {
      this.uploadDisabled = true;
      this.beUploading = true;
      event.target.onprogress = (event) => {
        // file.showProgress = true;
        let uploadPercent = parseFloat(
          ((event.loaded / event.total) * 100).toFixed(2)
        );
        file.percentage = uploadPercent;
        this.percent = uploadPercent;
        if (uploadPercent >= 100) {
          this.finish = true;
        }
      };
    },
    changePage(ind) {
      this.page = ind;
      sessionStorage.setItem('page', ind);
      this.SelectTask();
    },
    pageSizeChange(l) {
      this.limit = l;
      this.changePage(1);
    },

    //搜索任务名称
    SelectTask() {
      this.loading = true;
      this.showPage = false;
      this.$post(Api.loadAllResource(), {
        team_id: this.teamID > 0 ? this.teamID : this.userInfo.team_id,
        type: 2,
        bf_name: '',
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        this.modelData = [];
        this.total = res.data.total;
        if (res.data == undefined || res.data.data[0].dataListlength == 0) {
          return;
        }
        if (res.code == 1) {
          this.storageSpace = res.data.data[0].pm_space;
          var Ratio = Math.ceil(
            (this.storageSpace.use_space / this.storageSpace.storage_space) *
              100
          );
          this.$store.commit(
            'changeResidue',
            parseInt(
              this.storageSpace.storage_space - this.storageSpace.use_space
            ) *
              1024 *
              1024
          );
          Ratio >= 100
            ? (this.storageSpaceRatio = 100)
            : (this.storageSpaceRatio = Ratio);
          this.modelData = res.data.data[0].data_list;
          setTimeout(() => {
            this.initMaps(); //加载地图
          }, 400);
          this.modelData.map((el) => {
            el.file_size = (el.file_size / 1024).toFixed(2);
            // if (el.mode_type == 2) {
            //   el.modelType = orthographic; // 图片地址
            // } else {
            //   el.modelType = model_icon; // 图片地址
            // }
          });

          //   res.data.data[0].data_list.forEach((item) => {
          //     item.file_size = (item.file_size / 1024 / 1024).toFixed(2);
          //     // 1 模型 2 正射
          //     if (item.mode_type == 2) {
          //       item.modelType = orthographic; // 图片地址
          //     } else {
          //       item.modelType = model_icon; // 图片地址
          //     }
          //     this.modelData.push(item);
          //   });
          this.loading = false;
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },
    initMaps() {
      this.modelData.map((ele) => {
        new AMap.Map('map' + ele.id, {
          dragEnable: false,
          resizeEnable: false,
          zoom: 16,
          zooms: [16, 16],
          center: [ele.lng, ele.lat],
          layers: [new AMap.TileLayer.Satellite()],
        });
      });

      //   layer = [
      //       new AMap.TileLayer({
      //         getTileUrl: function (x, y, z) {
      //           return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png';
      //         },
      //         zIndex: 16,
      //       }),
      //     ];
    },
    //删除模型或正射
    remove() {
      this.deleteModelShou = false;
      let row = this.deleteModelData;
      if (row.mode_type == 2) {
        //正射
        this.$post(Api.deleteOrthophoto(), {
          id: row.id,
          map_id: row.kmlmsg[0].kml_id,
        }).then((res) => {
          this.deleteModelData = {};
          if (res.code == 1) {
            this.$Message.info(res.msg_customer);
            this.SelectTask();
          } else {
            this.$Message.error(res.msg_customer);
          }
        });
      } else {
        //模型
        this.$post(Api.deleteModel(), {
          model_id: row.id,
        }).then((res) => {
          this.deleteModelData = {};
          if (res.code == 1) {
            this.$Message.info(res.msg_customer);
            this.SelectTask();
          } else {
            this.$Message.error(res.msg_customer);
          }
        });
      }
    },
    chooseType() {
      let that = this;
      let marker = '';
      let layer = [];
      if (this.$online) {
        layer = [new AMap.TileLayer(), new AMap.TileLayer.Satellite()];
        // layer = [new AMap.TileLayer()];
      } else {
        layer = [
          new AMap.TileLayer({
            getTileUrl: function (x, y, z) {
              return Api.GaoDeSatelliteMap() + z + '/' + x + '/' + y + '.png';
            },
            zIndex: 10,
          }),
        ];
      }
      this.addMap = new AMap.Map('addMap', {
        layers: layer,
        center: this.$Center,
        // expandZoomRange: true, // 扩展地图缩放级别
        zoom: 16,
        zooms: [3, 18],
        // resizeEnable: true,
        features: ['bg', 'road', 'building', 'point'],
      });

      //   this.addMap.setFeatures(['bg', 'road', 'building']);

      this.location = '';
      that.orthographicUpLoadData.lat = '';
      that.orthographicUpLoadData.lng = '';

      var autoComplete = new AMap.Autocomplete({ input: 'mapSearch_3' });
      AMap.event.addListener(autoComplete, 'select', function (e) {
        that.addMap.setCenter([e.poi.location.lng, e.poi.location.lat]);
      });

      this.addMap.on('click', function (e) {
        if (that.$online) {
          that.orthographicUpLoadData.lng = e.lnglat.R;
          that.orthographicUpLoadData.lat = e.lnglat.Q;
          that.getAddsByPosition(e.lnglat);
        } else {
          that.orthographicUpLoadData.lng = e.lnglat.O;
          that.orthographicUpLoadData.lat = e.lnglat.P;
        }
        if (marker == '') {
          marker = new AMap.Marker({
            position: e.lnglat,
            map: that.addMap,
          });
        } else {
          marker.setPosition(e.lnglat);
        }
      });
    },
    // 上传
    handleFormatError(file) {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '文件格式请选择zip压缩包.',
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: '超过限定大小限制',
        desc: '上传的图片大小不得大于30G。',
      });
    },

    // OrthophotoSuccess(res) {
    //   let url, params;
    //   url = AddYinshe();
    //   params = {
    //     mapname: this.orthographicUpLoadData.mapname,
    //     team_id: this.userInfo.team_id,
    //     lat: this.orthographicUpLoadData.lat,
    //     lng: this.orthographicUpLoadData.lng,
    //     file: res.data.decom_path,
    //     file_size: parseInt(this.file_size / 1024),
    //     mode_type: 2, // 地图
    //   };

    //   this.$post(url, params).then((res) => {
    //     if (res.code === 1) {
    //       this.$Message.success('文件上传成功');
    //       this.teamID = this.userInfo.team_id;
    //       this.addModelShou = false;
    //       // 清空数据
    //     } else {
    //       this.$Message.error('文件有错，添加失败！');
    //     }
    //     this.closeAddModel();
    //     this.SelectTask();
    //   });
    //   this.uploadDisabled = false;
    //   this.beUploading = false;
    //   this.finish = false;
    // },
    OrthophotoError(error) {
      this.beUploading = false;
      this.uploadDisabled = false;
      this.finish = false;
      this.$Message.error('上传失败');
      // 清空上传列表
      this.$refs.OrUpLoad.clearFiles();
      console.log(error);
    },
    // 关闭添加框,清空数据
    closeAddModel() {
      this.addModelShou = false;
      this.orthographicUpLoadData = {
        mapname: '',
        // team_id: this.userType == 0 ? this.Project1info.pm_id : this.teamID,
        lng: '',
        lat: '',
      };
    },
    // 编辑模型 模型重命名
    redactModel_ok() {
      let url, params;
      // 正射
      url = Api.updateOrthophoto();
      params = {
        ortho_id: this.redactModelData.id,
        team_id: this.redactModel.tid,
        map_name: this.redactModel.name,
      };

      this.$post(url, params).then((res) => {
        if (res.code == 1) {
          // 编辑成功
          this.$Message.success('编辑成功');
          this.redactModelShou = false;
          this.SelectTask();
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },
    //关闭编辑窗口
    closeredactModel() {
      this.redactModelData = {};
      this.redactModel.name = '';
      this.redactModel.tid = '';
    },
    //预览正射
    lookOrthophotoFn(row) {
      this.$router.push({
        path: '/orthographicShou',
        query: { id: row.kmlmsg[0].kml_id },
      });
    },
    // 重命名
    edit(row) {
      if (this.userInfo.is_manager != 1) {
        this.$Message.error('飞手账号无编辑权限');
        return;
      } else {
        this.redactModelData = row;
        this.redactModel.name = row.mapname;
        this.redactModel.tid = row.team_id;
        this.redactModelShou = true;
      }
    },

    // 删除
    del(row) {
      if (this.userInfo.is_manager != 1) {
        this.$Message.error('飞手账号无删除权限');
        return;
      } else {
        this.deleteModelShou = true;
        this.deleteModelData = row;
      }
    },
  },

  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'));
    this.CommonUploadURL = UploadFile();
    this.upLoadFileData.token = sessionStorage.getItem('token');
    this.userType = sessionStorage.getItem('userType');
    // 上传模型只能上传给自身所在团队
    this.Project1info = JSON.parse(sessionStorage.getItem('Project1info'));
    this.loading = true;
    if (sessionStorage.getItem('page')) {
      this.page = sessionStorage.getItem('page');
    }
    if (sessionStorage.getItem('team_id') === null) {
      this.teamID = this.userInfo.team_id;
      this.SelectTask();
    } else {
      this.teamID = Number(sessionStorage.getItem('team_id'));
      this.SelectTask();
    }

    this.orthographicUpLoadData.team_id = this.userInfo.team_id;
    // this.TerraShow = this.aaa.indexOf(this.userInfo.pm_id) == -1 ? false : true;

    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      this.teamID = value;
      this.changePage(1);
    });
    // this.$_bus.$off('reLoad2').$on('reLoad2', this.SelectTask());
    this.$_bus.$off('reLoad2').$on('reLoad2', () => {
      this.SelectTask();
    });

    this.$store.commit('changeFileType', 2);
  },
};
</script> 

<style scoped lang="scss">
.map-box {
  float: left;
  width: 2.36rem;
  height: 1.66rem;
  border-radius: 7px;
  margin-right: 0.24rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  position: relative;
  background: $xf_hei1;
  .type-name {
    position: absolute;
    border-radius: 7px 0 0 0;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 0 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #c9caca;
  }
  .map {
    width: 100%;
    height: 1.11rem;
    border-radius: 7px 7px 0 0;
  }
  .info {
    padding: 10px 10px 0;
    color: #a0a0a0;
    font-size: 0.12rem;
    .title {
      color: $font_color_1;
      font-size: 0.14rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 2rem;
    }
    .remark {
      margin-top: 0.13rem;
      color: #656565;
      width: 2rem;
      overflow: hidden; /* 溢出隐藏 */
      display: -webkit-box; /* 老版弹性盒子，存在浏览器兼容问题，需要添加-webkit，表示内容在水平方向上的排放 */
      -webkit-line-clamp: 2; /* 溢出的行数 */
      -webkit-box-orient: vertical; /* 规定内容水平排放 */
      text-overflow: ellipsis;
      white-space: pre;
    }
    .more {
      position: absolute;
      top: 1.3rem;
      right: 0.05rem;
      > div {
        display: none;
        position: absolute;
        width: 1.06rem;
        height: 1rem;
        // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: $xf_hei4;
        top: 25px;
        right: 4px;
        z-index: 9;
        overflow: hidden;
        p {
          width: 100%;
          color: $font_color_1;
          height: 0.5rem;
          font-size: 0.14rem;
          line-height: 0.5rem;
          text-align: center;
        }
        p:hover {
          background-color: $xf_hui7_hover;
        }
      }
    }
    // .more:hover div {
    //   display: block;
    // }
    .more:hover {
      height: 22px;
      border-radius: 50%;
      background-color: $xf_hui3;
    }
  }
}
.finish {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(23, 23, 23, 0.84);
  text-align: center;
  color: #fff;
  font-size: 20px;
  padding-top: 30vh;
  p {
    margin-bottom: 20px;
  }
}
.camera {
  width: 70px;
  height: 70px;
  line-height: 70px;
  border: 1px dashed #333;
  text-align: center;
  margin-bottom: 10px;
}

#resource {
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 0.2rem;
  .wrapper {
    width: 100%;
    // height: 2rem;
    margin-bottom: 0.14rem;
    // padding: 0.3rem 0.4rem;
    // background-color: #3c3d43;
    // border: 1px solid #777777;
    // border-radius: 4px;
    padding: 0 0.4rem;

    .bottom {
      display: flex;
      height: 0.7rem;
      justify-content: space-between;
      align-items: center;
      .left {
        .item1 {
          width: 340px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .name {
            font-size: 16px;
            color: $white;
          }
          .stockpile {
            font-size: 12px;
            color: $white;
          }
        }
        .item3 {
          display: flex;
          align-items: center;
          .circle {
            display: flex;
            align-items: center;
          }
          .Box1 {
            > div {
              width: 10px;
              height: 10px;
              border-radius: 10px;
              background-color: #ecd500;
              margin-right: 10px;
            }
            > span {
              margin-right: 30px;
            }
          }
          .Box2 {
            > div {
              width: 10px;
              height: 10px;
              border-radius: 10px;
              background-color: #ff9500;
              margin-right: 10px;
            }
            > span {
              margin-right: 30px;
            }
          }
        }
      }
    }
  }

  .conent {
    padding: 0 0.4rem;
    // width: 1808px;
    // margin: 24px 20px;
    // padding: 24px 20px;
    // position: absolute;
    // background-color: #fff;
    // box-shadow: 0 3px 6px #ddd;
    // border-radius: 4px;
    // // top: 214px;
    // // bottom: 0;
    // left: 0;
    // overflow: auto;
  }
  .noMedia {
    font-size: 0.16rem;
    text-align: center;
    // margin: 50px 0;
    img {
      margin: 1rem auto 0.2rem;
    }
  }
}
// /deep/.addModel-modal {
//   .action-wrapper {
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     .action {
//       padding: 20px;
//       display: flex;
//       align-items: center;
//       cursor: pointer;
//       opacity: 0.6;
//       .img {
//         width: 80px;
//         height: 80px;
//         margin-right: 20px;
//       }
//       span {
//         color: #666666;
//         font-size: 18px;
//         font-weight: bold;
//       }
//     }
//     .selected {
//       opacity: 1;
//     }
//     .Separator {
//       width: 1px;
//       height: 120px;
//       background-color: #d6d6d6;
//     }
//   }
// }
/deep/.Look-center-modal {
  .ivu-modal-body {
    padding: 0;
  }
  .OrthophotoContent {
    width: 100%;
    height: 100%;
    background-color: #ee2f3f;
  }
  .ModelContent {
    width: 100%;
    height: 100%;
    background-color: #b4b4b4;
  }
}
#map {
  width: 648px;
  height: 470px;
  position: relative;
  .mapSearch {
    position: absolute;
    top: 14px;
    left: 11px;
    z-index: 400;
    width: 290px;
    height: 40px;
    background: rgba(23, 23, 23, 0.71);
    border: 1px solid #454545;
    line-height: 40px;
    text-align: left;

    input {
      background: transparent;
      outline: none;
      border: 0;
      color: #fff;
    }
  }
  #addMap {
    width: 100%;
    height: 100%;
  }
  .tiptop {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 30;
    left: 0;
    text-align: center;
    background-color: #f19900;
    color: #000;
    height: 25px;
    line-height: 25px;
  }
}
/deep/.ivu-form-item {
  margin-bottom: 20px !important;
}
/deep/ .ivu-progress-inner {
  overflow: hidden;
}
</style>